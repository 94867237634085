import d3 from "d3";
import CountryFlag from "../CountryFlag/";

let d3Chart = {};

d3Chart.create = function (el, props, countryOrdersCount) {
  let margin = { top: 40, right: 0, bottom: 0, left: 0 };

  this.width = props.width - margin.left - margin.right;
  this.height = props.height - margin.top - margin.bottom;

  d3.select(el)
    .append("svg")
    .attr("width", this.width + margin.left + margin.right)
    .attr("height", this.height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
};

d3Chart.update = function (el, props, countryOrdersCount) {
  this.drawBars(el, props, countryOrdersCount);
};

d3Chart.colorFor = function (i) {
  return ["#FF7200", "#28D0D9", "#9376E4", "#27DA99", "#FB6281", "#FADC62"][i];
};

d3Chart.drawBars = function (el, props, countryOrdersCount) {
  let self = this;
  let data = countryOrdersCount.sort((a, b) => b.value - a.value);
  let width = this.width;
  let height = this.height;

  let x = d3.scale.ordinal().rangeRoundBands([0, width], 0.04);

  let y = d3.scale.linear().range([height, 0]);

  let svg = d3.select(el).select("svg").select("g");
  x.domain(
    data.map(function (d) {
      return d.country_tag;
    })
  );
  y.domain([
    0,
    d3.max(data, function (d) {
      return d.value;
    }),
  ]);

  let bar = svg.selectAll("rect").data(data);
  bar.exit().remove();
  bar.enter().append("rect").attr("class", "rectbar");
  bar
    .attr("x", (d) => x(d.country_tag))
    .attr("width", x.rangeBand())
    .attr("y", (d) => y(d.value))
    .attr("height", (d) => height - y(d.value))
    .attr("style", (d, i) => "fill:" + self.colorFor(i) + ";stroke-width:0.0");

  let label = svg.selectAll(".percentage-label").data(data);
  label.exit().remove();
  label.enter().append("text").attr("class", "percentage-label").style("text-anchor", "middle");
  label
    .attr("x", (d) => x(d.country_tag) + x.rangeBand() / 2)
    .attr("y", (d) => (height + y(d.value)) / 2)
    .text((d) => d.formatted_value || d.value);

  let flag_width = 25,
    flag_height = 25;

  let flags = svg.selectAll("image").data(data);
  flags.exit().remove();
  flags.enter().append("svg:image").attr("width", flag_width).attr("height", flag_height);
  flags
    .attr("x", (d) => x(d.country_tag) + (x.rangeBand() - flag_width) / 2)
    .attr("y", (d) => y(d.value) - 1.3 * flag_height)
    .style("text-anchor", "middle")
    .attr("xlink:href", (d) => CountryFlag[d.country_tag]);
};

export default d3Chart;
