import React, { Component } from "react";

import Tile from "../../bricks/Tile/";
import DeviceOrdersChart from "../../bricks/DeviceOrdersChart/";
import UsersSinceBigBang from "../../bricks/UsersSinceBigBang/";
import QuarterObjective from "../../bricks/QuarterObjective/";
import CountryOrdersChart from "../../bricks/CountryOrdersChart/";

import "./style.css";

class SalesDashboardChart extends Component {
  render() {
    let chartEuroFormat = (data) =>
      data.map((data) => ({ ...data, formatted_value: euroFormat(data.value) }));
    let percentFormat = (x) => x + "%";
    let euroFormat = (x) => Math.floor(x / 100).toLocaleString("fr-FR") + "€";
    let numberFormat = (x) => x.toLocaleString("fr-FR");
    let hourFormat = (x) => Math.round(x) + "h";

    return (
      <div className="zkrin-container">
        <div className="flex-grid">
          <Tile
            text={euroFormat(this.props.dataTree.year_revenues.data.total_cents)}
            legend="Total this year"
            style={{ background: "#2d86cd" }}
          />
          <QuarterObjective
            income={this.props.dataTree.quarter_revenues.data.total_cents}
            target={1030000000}
            quarter={3}
            euroFormat={euroFormat}
            style={{ background: "#2d86cd" }}
          />
          <Tile
            text={euroFormat(this.props.dataTree.month_revenues.data.total_cents)}
            legend="Total this month"
            style={{ background: "linear-gradient(150deg, #00e7ff, #ef9f9f)" }}
          />
          <Tile
            text={numberFormat(this.props.dataTree.total_customers.data.customers_count)}
            legend="Total Customers"
            legend2={
              "repeater: " +
              percentFormat(
                Math.floor(
                  (100 * this.props.dataTree.total_repeaters.data.repeaters_count) /
                    this.props.dataTree.total_customers.data.customers_count
                )
              )
            }
            style={{ background: "linear-gradient(150deg, #29b9e5, #8f2a74)" }}
          />
          <UsersSinceBigBang value={this.props.dataTree.users_count.data.users_count} />
        </div>

        <div className="flex-grid">
          <Tile
            text={percentFormat(
              Math.floor(100 * this.props.dataTree.last30_days_promo_rate.data.rate)
            )}
            legend="Last 30 days promo rate"
            rate={Math.floor(100 * this.props.dataTree.promo_rate.data.rate)}
            conditionalStyle={(props) => {
              if (props.rate < 12) {
                return "success-tile";
              } else if (props.rate < 15) {
                return "warning-tile";
              } else {
                return "danger-tile";
              }
            }}
            style={{ background: "linear-gradient(150deg, #e10808, #eb6af3)" }}
          />
          <DeviceOrdersChart
            data={chartEuroFormat(this.props.dataTree.last30_days_device_revenues.data)}
            height="170"
            width="240"
            legend="Last 30 days' revenues by device"
          />
          <Tile
            text={hourFormat(this.props.dataTree.average_shipping_delay.data.average)}
            legend={
              "Average shipping delay of the last " +
              this.props.dataTree.average_shipping_delay.data.order_limit +
              " orders"
            }
            style={{ background: "linear-gradient(150deg, #2BC7FF, #8880FF)" }}
          />

          <Tile
            text={euroFormat(this.props.dataTree.day_average_cart.data.total_cents / 1.2)}
            legend="Cart average today (HT)"
            style={{ background: "linear-gradient(150deg, #11cba8, #4a56d4)" }}
          />

          <Tile
            text={percentFormat(
              Math.floor(100 * this.props.dataTree.day_revenues_new_customer.data.rate)
            )}
            legend="New customer Revenues rate today"
            conditionalStyle={(props) => {
              if (props.rate > 15) {
                return "success-tile";
              } else if (props.rate > 10) {
                return "warning-tile";
              } else {
                return "danger-tile";
              }
            }}
            style={{ background: "linear-gradient(150deg, #e10808, #eb6af3)" }}
          />
        </div>

        <div className="flex-grid">
          <Tile
            text={numberFormat(this.props.dataTree.new_orders.data.new_orders_count)}
            legend="Orders today"
            legend2={numberFormat(this.props.dataTree.new_orders.data.estimate) + " estimate"}
            style={{ background: "linear-gradient(150deg, #29b9e5, #9c3fba)" }}
            height="thin"
          />
          <Tile
            text={euroFormat(this.props.dataTree.day_revenues.data.total_cents)}
            legend="Revenues today"
            style={{ background: "linear-gradient(150deg, #11cba8, #4a56d4)" }}
            height="thin"
          />
          <Tile
            text={euroFormat(this.props.dataTree.day_repeater_revenues.data.total_cents)}
            legend="Repeat Revenues"
            style={{ background: "linear-gradient(150deg, #e1c608, #3ec21c)" }}
            height="thin"
          />
          <Tile
            text={numberFormat(this.props.dataTree.new_customers.data.new_customers_count)}
            legend="New customers today"
            style={{ background: "linear-gradient(150deg, #8f2a74, #01c0ae)" }}
            height="thin"
          />
          <Tile
            text={euroFormat(this.props.dataTree.day_revenues.data.estimate_cents)}
            legend="Today's forecast"
            style={{ background: "linear-gradient(60deg, #22d3e5, #9b96ed)" }}
            height="thin"
          />
        </div>

        <div className="flex-grid">
          <CountryOrdersChart
            data={this.props.dataTree.country_orders_counts.data}
            height="170"
            width="360"
            legend="Orders today"
          />
          <DeviceOrdersChart
            data={this.props.dataTree.device_orders_counts.data}
            legend="Orders today"
            height="170"
            width="240"
          />
          <CountryOrdersChart
            data={chartEuroFormat(this.props.dataTree.country_revenues.data)}
            height="170"
            width="360"
            legend="Revenues today"
          />
        </div>

        <div className="flex-grid">
          <Tile
            text={percentFormat(Math.floor(100 * this.props.dataTree.promo_rate.data.rate))}
            legend="Promo Rate Today"
            rate={Math.floor(100 * this.props.dataTree.promo_rate.data.rate)}
            conditionalStyle={(props) => {
              if (props.rate < 12) {
                return "success-tile";
              } else if (props.rate < 15) {
                return "warning-tile";
              } else {
                return "danger-tile";
              }
            }}
            style={{ background: "linear-gradient(150deg, #e10808, #eb6af3)" }}
            height="thin"
          />
          <Tile
            text={this.props.dataTree.album_sales.data.quantity}
            legend="Albums sold today"
            style={{ background: "linear-gradient(150deg, #05bacc, #bdd441)" }}
            height="thin"
          />
          <Tile
            text={this.props.dataTree.calendar_sales.data.quantity}
            legend="Calendars sold today"
            style={{ background: "linear-gradient(150deg, #5900e8, #52f38b)" }}
            height="thin"
          />
          <Tile
            text={numberFormat(this.props.dataTree.mondial_relay_orders.data.orders_count)}
            legend="Mondial Relay"
            style={{ background: "#B7194C" }}
            height="thin"
          />
          <Tile
            text={numberFormat(this.props.dataTree.express_orders.data.orders_count)}
            legend="Express"
            style={{ background: "#800080" }}
            height="thin"
          />
        </div>
      </div>
    );
  }
}

export default SalesDashboardChart;
