import React, { Component } from "react";

class DumpDetails extends Component {
  tileStyle() {
    if (this.props.status === "run") {
      return "success-tile";
    } else if (this.props.status === "skip") {
      return "warning-tile";
    } else {
      return "danger-tile";
    }
  }

  render() {
    return (
      <div className={"card tiny " + this.tileStyle()}>
        <span className="legend-infos">{this.props.name}</span>
        <span className="legend-infos-small legend-infos" title={this.props.status}>
          status: {this.props.status}
        </span>
      </div>
    );
  }
}

export default DumpDetails;
