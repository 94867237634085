import React, { Component } from "react";
import "./style.scss";

class Tile extends Component {
  className() {
    var className = this.props.className || "";
    var styleCondition = this.props.conditionalStyle;
    if (styleCondition && styleCondition(this.props)) {
      return ` ${className} ${styleCondition(this.props)}`;
    } else {
      return ` ${className}`;
    }
  }

  render() {
    var height = this.props.height || "medium";
    var legend, legend2;
    if (this.props.legend) {
      legend = (
        <span className="legend-infos">
          <br />
          {this.props.legend}
        </span>
      );
    }
    if (this.props.legend2) {
      legend2 = (
        <span className="legend-infos">
          <br />
          {this.props.legend2}
        </span>
      );
    }
    return (
      <div
        className={"card stat-cell legend-text " + height + this.className()}
        style={this.props.style}
      >
        <span className="big-number">{this.props.text}</span>
        {legend}
        {legend2}
      </div>
    );
  }
}

export default Tile;
