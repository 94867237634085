let apiURL = "https://zkrin-api2.cheerz.com";
if (process.env.NODE_ENV === "development") {
  apiURL = "http://localhost:3000";
}

let ZkrinAPI = {
  url: apiURL,

  path: (path) => `${apiURL}/${path}`,
  pages: (resource) => `${apiURL}/pages/${resource}`,
  databrickURL: (brick) => `${apiURL}/data_bricks/${brick}`,
};
export default ZkrinAPI;
