import React, { Component } from "react";

class ProductFamilyDots extends Component {
  delayBackground(rate) {
    switch (true) {
      case rate >= 95:
        return "linear-gradient(150deg, #00ea80, #576fa0)";
      default:
        return "linear-gradient(150deg, #ea3d00, #df7272)";
    }
  }

  render() {
    return (
      <div className="flex-grid">
        {this.props.productFamiliesDots.map((productFamilyDots) => {
          return (
            <div>
              <div
                className={"card stat-cell super-thin"}
                style={{ background: "linear-gradient(150deg, #2ca0bc, #b16581)" }}
              >
                <span className="thin-tile-content">
                  DOT
                  <br />
                  {productFamilyDots.product_family_name}
                </span>
                {productFamilyDots.shipping_day_limit != null && (
                  <span>{productFamilyDots.shipping_day_limit}j </span>
                )}
              </div>
              {productFamilyDots.values.map((value) => {
                return (
                  <div
                    className={"card stat-cell super-thin"}
                    style={{ background: this.delayBackground(value.rate) }}
                  >
                    {value.new_system === true && (
                      <span className="legend-text">(nouveau calcul)</span>
                    )}
                    <span className="thin-tile-content">{value.rate}%</span>
                    <span>{value.time_period}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ProductFamilyDots;
