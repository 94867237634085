import React, { Component } from "react";
import SalesDashboard from "../../dashboards/SalesDashboard/";
import DataTreeFetcher from "../../../models/dataTreeFetcher";
import ZkrinAPI from "../../../models/zkrinAPI";

class SalesDashboardPage extends Component {
  constructor(props) {
    super(props);

    const countryObjs = [
      {
        country_tag: "FRA",
        device_tag: "iphone",
        progress: 5,
        month_relative_progress: 0,
        value: 10,
      },
      {
        country_tag: "BEL",
        device_tag: "android",
        progress: 4,
        month_relative_progress: 2,
        value: 30,
      },
      {
        country_tag: "DEU",
        device_tag: "web_app",
        progress: 20,
        month_relative_progress: 2,
        value: 40,
      },
    ];

    this.state = {
      year_revenues: { data: { total_cents: 0 } },
      new_orders: { data: { estimate: 0, new_orders_count: 0 } },
      new_customers: { data: { new_customers_count: 0 } },
      total_customers: { data: { customers_count: 0 } },
      promo_rate: { data: { rate: 0 } },
      device_orders_counts: { data: countryObjs },
      month_revenues: { data: { total_cents: 0 } },
      mondial_relay_orders: { data: { orders_count: 0 } },
      express_orders: { data: { orders_count: 0 } },
      day_repeater_revenues: { data: { total_cents: 0 } },
      day_revenues: { data: { total_cents: 0, estimate_cents: 0 } },
      day_revenues_new_customer: { data: { rate: 0 } },
      day_average_cart: { data: { total_cents: 0 } },
      mobile_sales: { data: { ratio: 0 } },
      average_shipping_delay: { data: { order_limit: 0 } },
      box_usage: { data: { ratio: 0 } },
      quarter_revenues: { data: { total_cents: 0 } },
      total_repeaters: { data: { repeaters_count: 0 } },
      users_count: { data: { users_count: 0 } },
      last30_days_promo_rate: { data: { rate: 0 } },
      album_sales: { data: { quantity: 0 } },
      calendar_sales: { data: { quantity: 0 } },
      last30_days_device_revenues: { data: [] },
      country_revenues: { data: [] },
      country_orders_counts: { data: [] },
    };
  }

  dataTree() {
    return {
      new_orders: {
        path: ZkrinAPI.databrickURL("orders_count"),
        refresh: 10,
        initial: { data: { estimate: 0, new_orders_count: 0 }, updated_at: new Date(0) },
      },
      device_orders_counts: {
        path: ZkrinAPI.databrickURL("device_orders_counts"),
        refresh: 10,
        initial: { data: [], updated_at: new Date(0) },
      },
      last30_days_device_revenues: {
        path: ZkrinAPI.databrickURL("last30_days_device_revenues"),
        refresh: 10,
        initial: { data: [], updated_at: new Date(0) },
      },
      day_revenues: {
        path: ZkrinAPI.databrickURL("day_revenues"),
        refresh: 10,
        initial: { data: { estimate_cents: 0, total_cents: 0 }, updated_at: new Date(0) },
      },
      day_revenues_new_customer: {
        path: ZkrinAPI.databrickURL("day_revenues_new_customer"),
        refresh: 10,
        initial: { data: { rate: 0 }, updated_at: new Date(0) },
      },
      day_average_cart: {
        path: ZkrinAPI.databrickURL("day_average_cart"),
        refresh: 10,
        initial: { data: { total_cents: 0 }, updated_at: new Date(0) },
      },
      year_revenues: {
        path: ZkrinAPI.databrickURL("year_revenues"),
        refresh: 120,
        initial: { data: { total_cents: 0 }, updated_at: new Date(0) },
      },
      month_revenues: {
        path: ZkrinAPI.databrickURL("month_revenues"),
        refresh: 10,
        initial: { data: { total_cents: 0 }, updated_at: new Date(0) },
      },
      quarter_revenues: {
        path: ZkrinAPI.databrickURL("quarter_revenues"),
        refresh: 10,
        initial: { data: { total_cents: NaN }, updated_at: new Date(0) },
      },
      country_orders_counts: {
        path: ZkrinAPI.databrickURL("country_orders_counts"),
        refresh: 10,
        initial: { data: [], updated_at: new Date(0) },
      },
      country_revenues: {
        path: ZkrinAPI.databrickURL("country_revenues"),
        refresh: 10,
        initial: { data: [], updated_at: new Date(0) },
      },
      new_customers: {
        path: ZkrinAPI.databrickURL("new_customers"),
        refresh: 10,
        initial: { data: { new_customers_count: 0 }, updated_at: new Date(0) },
      },
      total_customers: {
        path: ZkrinAPI.databrickURL("customers_count"),
        refresh: 10,
        initial: { data: { customers_count: 0 }, updated_at: new Date(0) },
      },
      total_repeaters: {
        path: ZkrinAPI.databrickURL("repeaters_count"),
        refresh: 10,
        initial: { data: { repeaters_count: 0 }, updated_at: new Date(0) },
      },
      sales_objective_progress: {
        path: ZkrinAPI.databrickURL("global_sales_objective"),
        refresh: 20,
        initial: { data: { progress: 0 }, updated_at: new Date(0) },
      },
      sales_objective_progresses: {
        path: ZkrinAPI.databrickURL("country_sales_objective"),
        refresh: 20,
        initial: { data: [], updated_at: new Date(0) },
      },
      album_sales: {
        path: ZkrinAPI.databrickURL("album_sales"),
        refresh: 20,
        initial: { data: { quantity: 0 }, updated_at: new Date(0) },
      },
      calendar_sales: {
        path: ZkrinAPI.databrickURL("calendar_sales"),
        refresh: 20,
        initial: { data: { quantity: 0 }, updated_at: new Date(0) },
      },
      promo_rate: {
        path: ZkrinAPI.databrickURL("promo_rate"),
        refresh: 10,
        initial: { data: { rate: 0 }, updated_at: new Date(0) },
      },
      last30_days_promo_rate: {
        path: ZkrinAPI.databrickURL("last30_days_promo_rate"),
        refresh: 10,
        initial: { data: { rate: 0 }, updated_at: new Date(0) },
      },
      day_repeater_revenues: {
        path: ZkrinAPI.databrickURL("repeater_revenues"),
        refresh: 10,
        initial: { data: { total_cents: 0 }, updated_at: new Date(0) },
      },
      users_count: {
        path: ZkrinAPI.databrickURL("users_count"),
        refresh: 10,
        initial: { data: { users_count: 0 }, updated_at: new Date(0) },
      },
      customers_count: {
        path: ZkrinAPI.databrickURL("customers_count"),
        refresh: 10,
        initial: { data: { customers_count: 0 }, updated_at: new Date(0) },
      },
      mondial_relay_orders: {
        path: ZkrinAPI.databrickURL("mondial_relay_orders"),
        refresh: 10,
        initial: { data: { last_order: 0, orders_count: 0 }, updated_at: new Date(0) },
      },
      express_orders: {
        path: ZkrinAPI.databrickURL("express_orders"),
        refresh: 10,
        initial: { data: { last_order: 0, orders_count: 0 }, updated_at: new Date(0) },
      },
      average_shipping_delay: {
        path: ZkrinAPI.databrickURL("average_shipping_delay"),
        refresh: 10,
        initial: { data: { average: 0, order_limit: 0 }, updated_at: new Date(0) },
      },
    };
  }

  componentDidMount() {
    DataTreeFetcher.dataTree = this.dataTree();
    DataTreeFetcher.subscribe((x) => this.setState(x));
  }

  render() {
    return (
      <div className="App">
        <SalesDashboard dataTree={this.state} />
      </div>
    );
  }
}

export default SalesDashboardPage;
