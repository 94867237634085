import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import WithAuth from "./WithAuth";
import AuthenticatedApp from "./AuthenticatedApp";

const AuthedApp = WithAuth(AuthenticatedApp);

class App extends Component {
  render() {
    return (
      <Router>
        <AuthedApp />
      </Router>
    );
  }
}

export default App;
