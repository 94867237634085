import React, { Component } from "react";
import ReactDOM from "react-dom";
import d3 from "d3";
import "./style.css";

class DeviceOrdersChart extends Component {
  componentDidMount() {
    this.initChart();
    this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  radius() {
    let margin = 0;
    return Math.min(this.props.width, this.props.height) / 2 - margin;
  }

  innerRadius() {
    return 0.35 * this.radius();
  }

  initChart() {
    let el = ReactDOM.findDOMNode(this);

    d3.select(el)
      .append("svg")
      .attr("width", this.props.width)
      .attr("height", this.props.height)
      .append("g")
      .attr("transform", "translate(" + this.props.width / 2 + "," + this.props.height / 2 + ")");
  }

  updateChart() {
    let el = ReactDOM.findDOMNode(this);

    let colorFor = function (deviceTag) {
      return {
        web_app: "#fed100",
        iphone: "#00ccfe",
        android: "#03ec97",
      }[deviceTag];
    };

    let dataset = this.props.data;

    var pie = d3.layout
      .pie()
      .sort(null)
      .value(function (d) {
        return d.value;
      });

    var arc = d3.svg.arc().innerRadius(this.innerRadius()).outerRadius(this.radius());

    var svg = d3.select(el).select("svg").select("g");

    var arcs = svg.selectAll("path").data(pie(dataset));
    var texts = svg.selectAll("text").data(pie(dataset));

    arcs.enter().append("path");
    texts.enter().append("text");

    arcs
      .attr("fill", function (d, i) {
        return colorFor(d.data.device_tag);
      })
      .attr("d", arc);

    texts
      .attr("transform", function (d) {
        d.innerRadius = 0;
        d.outerRadius = this.radius;
        return "translate(" + arc.centroid(d) + ")";
      })
      .attr("class", "device-percentage-label")
      .attr("text-anchor", "middle")
      .text(function (d, i) {
        return d.data.formatted_value || d.data.value;
      })
      .append("tspan")
      .attr("dy", 15)
      .attr("x", 0)
      .text(function (d, i) {
        return d.data.device_tag;
      });
  }

  render() {
    var legend;
    if (this.props.legend) {
      legend = <span className="legend-infos">{this.props.legend}</span>;
    }
    return (
      <div className="card medium">
        <div className="CountryOrdersChart"></div>
        {legend}
      </div>
    );
  }
}

export default DeviceOrdersChart;
