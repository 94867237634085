import React, { Component } from "react";
import { Route } from "react-router-dom";

export default function WithAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }

    componentDidMount() {
      fetch("https://zkrin-api2.cheerz.com/user_session", { method: "GET", credentials: "include" })
        .then((response) => response.json())
        .then((res) => {
          if (res["logged_in"]) {
            this.setState({ loading: false });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ loading: false, redirect: true });
        });
    }

    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect && process.env.NODE_ENV !== "development") {
        return (
          <Route
            component={() => {
              window.location.href = "https://zkrin-api2.cheerz.com/admin/login";
              return null;
            }}
          />
        );
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment>
      );
    }
  };
}
