import React, { Component } from "react";

class FactoryListPage extends Component {
  render() {
    return (
      <div className="zkrin-container">
        <div className="flex-grid">
          <a className="dash-btn" href="factory/gennevilliers">
            Genneviliers Dashboard
          </a>
        </div>
        <div className="flex-grid">
          <a className="dash-btn" href="factory/cewe">
            Cewe Dashboard
          </a>
        </div>
      </div>
    );
  }
}

export default FactoryListPage;
