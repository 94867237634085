import React, { Component } from "react";
import "./style.scss";

class UsersSinceBigBang extends Component {
  render() {
    return (
      <div className="card medium users-since-big-bang">
        <div className="content">
          <span className="users-since-big-bang-number">{this.props.value}</span>
          <br />
          <span className="users-since-big-bang-users">users</span>
          <br />
          <span className="users-since-big-bang-text">since big bang</span>
        </div>
      </div>
    );
  }
}

export default UsersSinceBigBang;
