import React from "react";
import DaySalesChart from "./d3";
import ReactDOM from "react-dom";

import "./style.css";

export default class TimeSalesChart extends React.Component {
  componentDidMount() {
    var el = ReactDOM.findDOMNode(this);
    DaySalesChart.create(el, this.props);
  }

  componentDidUpdate() {
    var el = ReactDOM.findDOMNode(this);
    DaySalesChart.update(el, this.props);
  }

  render() {
    return (
      <div className="card medium base-tile-background">
        <div className="time-sales-chart"></div>
      </div>
    );
  }
}
