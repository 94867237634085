import Immutable from "immutable";

let DataTreeFetcher = {
  dataTree: {},

  loadDataFromServer: function (dataTag, nodeDetails) {
    const fetchOptions = {
      cache: "no-cache",
    };

    if (!nodeDetails.public) {
      fetchOptions.credentials = "include";
    }

    fetch(nodeDetails.path, fetchOptions)
      .then((response) => response.json())
      .catch((err) => {
        console.error(err);
      })
      .then((result) => {
        if (nodeDetails.action) {
          nodeDetails.action(result);
        }

        var dataOrInitial;
        if (
          result.updated_at &&
          result.max_age &&
          this.isDataOutdated(result.max_age, result.updated_at)
        ) {
          console.error(dataTag, "is outdated");
          dataOrInitial = nodeDetails.initial;
        } else {
          dataOrInitial = result;
        }
        let nodeResult = Immutable.Map({ [dataTag]: dataOrInitial });
        this.state = Immutable.Map(this.state).merge(nodeResult).toObject();
        this.callback(this.state);
      })
      .catch((err) => {
        console.error(err);
      });
  },

  defaultValue: function (dataTree) {
    let r = Object.keys(dataTree).reduce((h, k) => {
      h[k] = dataTree[k].initial;
      h[k].data_uptodate = false;
      return h;
    }, {});
    return r;
  },

  loadAndRepeat: function (dataTag, nodeDetails) {
    this.loadDataFromServer(dataTag, nodeDetails);
    var f = function () {
      this.loadDataFromServer(dataTag, nodeDetails);
    }.bind(this);
    setInterval(f, nodeDetails.refresh * 1000);
  },

  subscribe: function (callback) {
    this.callback = callback;
    this.launch();
  },

  launch: function () {
    for (const dataTag in this.dataTree) {
      var nodeDetails = this.dataTree[dataTag];
      this.loadAndRepeat(dataTag, nodeDetails);
    }
  },

  isDataOutdated: function (maxAgeInMinutes, updatedAt) {
    let dataAge = Date.now() - Date.parse(updatedAt); // Comparing UTC timestamps
    let dataAgeInMinutes = dataAge / 60000;
    if (dataAgeInMinutes > maxAgeInMinutes + 5) {
      return true;
    } else {
      return false;
    }
  },
};
export default DataTreeFetcher;
