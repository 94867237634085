import React, { Component } from "react";

class NotFoundPage extends Component {
  render() {
    return (
      <div className="zkrin-container">
        404 - Not Found{" "}
        <span role="img" aria-label="Not found">
          🛑
        </span>
      </div>
    );
  }
}

export default NotFoundPage;
