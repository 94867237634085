import React, { Component } from "react";
import DataTreeFetcher from "../../../models/dataTreeFetcher";
import ZkrinAPI from "../../../models/zkrinAPI";
import "./style.css.scss";
import ProductFamilyDots from "../../bricks/ProductFamilyDots";
import ProductFamilyQueues from "../../bricks/ProductFamilyQueues";
import FactoryTile from "../../bricks/FactoryTile";

class FactoryDashboardPage extends Component {
  constructor(props) {
    super(props);
    this.factoryTag = this.props.match.params.factoryTag;
    this.state = DataTreeFetcher.defaultValue(this.dataTree(this.factoryTag));
  }

  dataTree(factoryTag) {
    return {
      productFamilyDots: {
        path: ZkrinAPI.databrickURL("product_family_dots"),
        refresh: 60,
        initial: { data: [], updated_at: new Date(0) },
      },
      productFamilies: {
        path: ZkrinAPI.databrickURL("product_families"),
        refresh: 10,
        initial: { data: { [`${factoryTag}`]: [] }, updated_at: new Date(0) },
      },
      lastHourSentPrintUnits: {
        path: ZkrinAPI.databrickURL("hour_sent_print_unit_count"),
        refresh: 10,
        initial: {
          data: { number_of_sent_print_units: { [`${factoryTag}`]: 0 }, updated_at: new Date(0) },
        },
      },
      lastDaySentPrintUnits: {
        path: ZkrinAPI.databrickURL("day_sent_print_unit_count"),
        refresh: 20,
        initial: {
          data: { number_of_sent_print_units: { [`${factoryTag}`]: 0 }, updated_at: new Date(0) },
        },
      },
      lastWeekSentPrintUnits: {
        path: ZkrinAPI.databrickURL("week_sent_print_unit_count"),
        refresh: 30,
        initial: {
          data: { number_of_sent_print_units: { [`${factoryTag}`]: 0 }, updated_at: new Date(0) },
        },
      },
    };
  }

  componentDidMount() {
    DataTreeFetcher.dataTree = this.dataTree(this.factoryTag);
    DataTreeFetcher.subscribe((x) => this.setState(x));
  }

  delayBackground(delay) {
    switch (true) {
      case delay < 24:
        return "linear-gradient(150deg, #9ccf85, #00ea8f)";
      case delay < 36:
        return "linear-gradient(150deg, #e53850, #9b787d)";
      default:
        return "#ff5e00";
    }
  }

  render() {
    var numberFormat = (x) => x.toLocaleString("fr-FR");
    return (
      <div className="factory">
        <div className="zkrin-container">
          <div className="flex-grid">
            <FactoryTile
              text={numberFormat(
                this.state.lastWeekSentPrintUnits.data.number_of_sent_print_units[this.factoryTag]
              )}
              legend="Expeditions cette semaine"
              style={{ background: "linear-gradient(150deg, #09e9f7, #576fa0)" }}
            />
            <FactoryTile
              text={numberFormat(
                this.state.lastDaySentPrintUnits.data.number_of_sent_print_units[this.factoryTag]
              )}
              legend="Expeditions aujourd'hui"
              style={{ background: "linear-gradient(150deg, #09e9f7, #576fa0)" }}
            />
            <FactoryTile
              text={numberFormat(
                this.state.lastHourSentPrintUnits.data.number_of_sent_print_units[this.factoryTag]
              )}
              legend="Expeditions en 1h"
              style={{ background: "linear-gradient(150deg, #09e9f7, #576fa0)" }}
            />
          </div>
          <ProductFamilyQueues productFamilies={this.state.productFamilies.data[this.factoryTag]} />
          {this.factoryTag === "gennevilliers" && (
            <ProductFamilyDots productFamiliesDots={this.state.productFamilyDots.data} />
          )}
        </div>
      </div>
    );
  }
}

export default FactoryDashboardPage;
