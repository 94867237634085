import React, { Component } from "react";
import "./style.scss";

class FlashMessage extends Component {
  dismiss() {
    this.props.removeFlash();
  }

  render() {
    return (
      <div className={`alert alert-${this.props.type}`}>
        <p>
          {this.props.message}
          <button className="close-button" onClick={(e) => this.dismiss()}>
            x
          </button>
        </p>
      </div>
    );
  }
}

export default FlashMessage;
