import React, { Component } from "react";

class ProductFamilyQueues extends Component {
  render() {
    return (
      <div className="flex-grid">
        {this.props.productFamilies.map(function (productFamily) {
          return (
            <div
              className={"card stat-cell thin"}
              style={{ background: "linear-gradient(150deg, #f7b109, #576fa0)" }}
            >
              <span className="tile-content">{productFamily.pending_count}</span>
              <span>{productFamily.name} à envoyer</span>
              <br />
              <p className="legend-text">
                <span>
                  <br />
                  {productFamily.sent_count} expédiés aujourd'hui
                </span>
              </p>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ProductFamilyQueues;
