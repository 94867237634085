const niceDeployerNameMap = {
  Clauderucart: "Claude",
  Clementberger: "Clément",
  Elliottheldenbergh: "Elliott",
  Joakimcarrilho: "Joakim",
  Mathisguerin: "Mathis",
  Nicolaslechenic: "Nicolas",
  Pierrot: "Pierre",
  Thibaudpellissier: "Thibaud",
  Thomaswiart: "Tom",
  Valentintournier: "Valentin",
};

const niceDeployerName = (name) => {
  if (!name) {
    return "";
  }

  const niceName = niceDeployerNameMap[name];
  if (niceName) {
    return niceName;
  }

  return name;
};

export { niceDeployerName };
