import React, { Component } from "react";
import ZkrinAPI from "../../../models/zkrinAPI";
import FlashMessage from "../../FlashMessage";
import "./style.css";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = { message: undefined };
    this.removeFlash = this.removeFlash.bind(this);
  }

  removeFlash() {
    this.setState({ message: undefined });
  }

  launchAndroidUiTest() {
    fetch(ZkrinAPI.pages("android_ui_tests"), { method: "POST", credentials: "include" })
      .then((response) => {
        return Promise.all([response.json(), response.ok]);
      })
      .then(([res, ok]) => {
        if (ok) {
          this.setState({
            message: {
              type: "success",
              message: `The test build ${res.build_num} has been launched, you'll get notified in slack once it's finished`,
            },
          });
        } else {
          throw new Error(res.error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          message: { type: "error", message: `${err}` },
        });
      });
  }

  render() {
    return (
      <div className="zkrin-container">
        {this.state.message && (
          <FlashMessage removeFlash={this.removeFlash} {...this.state.message} />
        )}
        <div className="flex-grid">
          <a className="dash-btn" href="dashboards/sales">
            Sales Dashboard
          </a>
        </div>
        <div className="flex-grid">
          <a className="dash-btn" href="dashboards/factory">
            Factory Dashboards
          </a>
        </div>
        <div className="flex-grid">
          <a className="dash-btn" href="dashboards/tech">
            Tech Dashboard
          </a>
          <a className="dash-btn" href="dashboards/tech-server">
            Server
          </a>
        </div>
        <div className="flex-grid">
          <a className="dash-btn" href="dashboards/android-lint-reports">
            Android linter report
          </a>
        </div>
        <div className="flex-grid">
          <button className="dash-btn" onClick={(e) => this.launchAndroidUiTest()}>
            Launch Android UI Test
          </button>
        </div>
        <div className="flex-grid">
          <a className="logout-btn" href="https://zkrin-api2.cheerz.com/admin/logout">
            Sign-Out{" "}
            <span role="img" aria-label="Lock">
              🔒
            </span>
          </a>
        </div>
      </div>
    );
  }
}

export default HomePage;
