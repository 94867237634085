import BELpng from "./images/BEL.png";
import CHEpng from "./images/CHE.png";
import DEUpng from "./images/DEU.png";
import ESPpng from "./images/ESP.png";
import FRApng from "./images/FRA.png";
import GBRpng from "./images/GBR.png";
import ITApng from "./images/ITA.png";
import NLDpng from "./images/NLD.png";
import USApng from "./images/USA.png";

const CountryFlag = {
  CHE: CHEpng,
  DEU: DEUpng,
  ESP: ESPpng,
  FRA: FRApng,
  GBR: GBRpng,
  ITA: ITApng,
  NLD: NLDpng,
  USA: USApng,
  BEL: BELpng,
};

export default CountryFlag;
