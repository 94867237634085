import React from "react";
import { niceDeployerName } from "../../../helpers/deployer";

export default class ProductionDetails extends React.Component {
  render() {
    return (
      <a href="https://rpm.newrelic.com/accounts/288175/applications/7934607" className="tile-link">
        <div
          className="card thin"
          style={{ background: "linear-gradient(150deg, #2a69c7, #1bb0f7)" }}
        >
          <span className="big-number">{niceDeployerName(this.props.deployer)}</span>
          <span className="legend-infos-small" title={this.props.branch}>
            {this.props.branch}
          </span>
          <span className="legend-infos-small" style={{ fontWeight: "normal" }}>
            {this.props.deployed_at}
          </span>
          <span className="legend-infos">{this.props.name}</span>
        </div>
      </a>
    );
  }
}
