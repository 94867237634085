import React, { Component } from "react";

const percent = (income, target) => (income / target) * 100;
const centsToMillion = (number) => number / 100000000;
const twoDigits = (number) => parseFloat(Math.round(number * 100) / 100).toFixed(2);
const formatPercent = (income, target) => twoDigits(percent(income, target));
const formatMillion = (number) => centsToMillion(number).toFixed(1);

class QuarterObjective extends Component {
  renderTarget() {
    return (
      <span className="legend-infos">
        <br />
        (target: {formatMillion(this.props.target)} M€)
      </span>
    );
  }

  renderError() {
    return (
      <span>
        <span className="big-number">For Q{this.props.quarter}</span>
        {this.renderTarget()}
      </span>
    );
  }

  renderSuccess() {
    return (
      <span>
        <span>
          <span className="big-number">{this.props.euroFormat(this.props.income)}</span>
          <br />
          <span className="medium-number">
            ({formatPercent(this.props.income, this.props.target)}%)
          </span>
        </span>
        <span className="legend-infos">
          <br />
          Total in Q{this.props.quarter}
        </span>
        {this.renderTarget()}
      </span>
    );
  }

  render() {
    const success = !isNaN(this.props.income);
    return (
      <div className="card stat-cell legend-text medium" style={this.props.style}>
        {success && this.renderSuccess()}
        {!success && this.renderError()}
      </div>
    );
  }
}

export default QuarterObjective;
