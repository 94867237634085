import React, { Component } from "react";

class FactoryTile extends Component {
  render() {
    var height = this.props.height || "thin";
    var contentClass = this.props.contentClass || "tile-content";

    var legend;
    if (this.props.legend) {
      legend = (
        <p className="legend-text">
          <span>
            <br />
            {this.props.legend}
          </span>
        </p>
      );
    }
    return (
      <div className={"card stat-cell " + height} style={this.props.style}>
        <span className={contentClass}>{this.props.text}</span>
        {legend}
      </div>
    );
  }
}

export default FactoryTile;
