import React from "react";
import "./style.css";

export default class DataDogFrame extends React.Component {
  render() {
    return (
      <div className="datadog_card thin">
        <iframe title={this.props.frameUrl} src={this.props.frameUrl} />
      </div>
    );
  }
}
