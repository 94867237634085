import React, { Component } from "react";
import "./style.css";

class AppDetails extends Component {
  render() {
    let roundNumber = (x, y) => Number(x || 0).toFixed(y);

    return (
      <div className={"app-details-card legend-text medium"} style={this.props.style}>
        <span className="app-details-big-number">
          {roundNumber(this.props.data.conversion, 2) + "%"}
        </span>
        <span className="app-details-legend">
          <br />
          {this.props.platformName + " " + this.props.data.version + " conversion"}
        </span>
        <span className="app-details-legend">
          <br />
          {this.props.data.sessions + " sessions"}
        </span>
      </div>
    );
  }
}

export default AppDetails;
