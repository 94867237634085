import React, { Component } from "react";
import ZkrinAPI from "../../../models/zkrinAPI";

import "./style.scss";

class AndroidLintReports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    fetch(ZkrinAPI.pages("android_last_linter_report"), { method: "GET", credentials: "include" })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ url: res.url });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    return <iframe title="last android linter report" src={this.state.url} />;
  }
}

export default AndroidLintReports;
