import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SalesDashboardPage from "./components/pages/Sales";
import HomePage from "./components/pages/Home";
import TechPage from "./components/pages/Tech";
import TechServer from "./components/pages/TechServer";
import AndroidLintReports from "./components/pages/AndroidLintReports";
import AndroidUiTest from "./components/pages/AndroidUiTest";
import DumpStatus from "./components/pages/DumpStatus";
import NotFoundPage from "./components/pages/NotFound";
import FactoryListPage from "./components/pages/FactoryList";
import FactoryDashboardPage from "./components/pages/Factory";

import "./App.css";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            path="/dashboards/factory"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={FactoryListPage} exact />
                <Route path={`${url}/:factoryTag`} component={FactoryDashboardPage} />
              </>
            )}
          />
          <Route path="/dashboards/sales" component={SalesDashboardPage} />
          <Route path="/dashboards/tech" component={TechPage} />
          <Route path="/dashboards/tech-server" component={TechServer} />
          <Route path="/dashboards/android-lint-reports" component={AndroidLintReports} />
          <Route path="/dashboards/android-test-ui/:build_num" component={AndroidUiTest} />
          <Route path="/dashboards/dump-status" component={DumpStatus} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    );
  }
}

export default App;
