import React from "react";
import "./style.css";

export default class UnsyncedOrders extends React.Component {
  tileStyle(count) {
    if (count < 1) {
      return "success-tile";
    } else {
      return "danger-tile";
    }
  }

  render() {
    var ordersCount = parseInt(this.props.count);
    return (
      <a href="https://www.cheerz.com/admin_panel/orders?scope=with_problem" className="tile-link">
        <div className={"app-details-card legend-text thin " + this.tileStyle(ordersCount)}>
          <span className="medium-title">{this.props.count}</span>
          <span className="legend-infos">
            <br />
            Unsynced orders
          </span>
        </div>
      </a>
    );
  }
}
