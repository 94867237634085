import React, { Component } from "react";
import DataTreeFetcher from "../../../models/dataTreeFetcher";
import ZkrinAPI from "../../../models/zkrinAPI";
import UnsyncedOrders from "../../bricks/UnsyncedOrders";
import DataDogFrame from "../../bricks/DataDogFrame";
import TimeSalesChart from "../../bricks/TimeSalesChart";
import AppDetails from "../../bricks/AppDetails";

import "./style.scss";

var datadog = {
  datadog_one_hour_urls: [
    "https://app.datadoghq.eu/graph/embed?token=8b75517ae8385bab2e96f1a354e8840fc46666eba70f640cda4d623f0a5a5b19&height=200&width=400&legend=true", // Konnektor - Orders Paid - iOS
    "https://app.datadoghq.eu/graph/embed?token=f104e068461893a404476f68f8e0770da6bd8130f91b355d1ea2f65977c1c7ef&height=200&width=400&legend=true", // Konnektor - Orders Paid - Android
    "https://app.datadoghq.eu/graph/embed?token=ee84e27fd22b317af3a295fee9e7351eac274b7596f8dd6c44916c4221be159c&height=200&width=400&legend=true", // Konnektor - Orders Paid - Web
    "https://app.datadoghq.eu/graph/embed?token=8888b99770380bc53a00dab3420d716b23add67b808059fe9dab7609984fabf0&height=200&width=400&legend=true", // Konnektor - Orders Paid
    "https://app.datadoghq.eu/graph/embed?token=b1ad84ae4c54c6667a6b6efd1ab75414dd9bfb10ee6bafa29ef07ac6da2c24d3&height=200&width=400&legend=true", // Cheerz Register
    "https://app.datadoghq.eu/graph/embed?token=5e4609c6b89a43d955357d86d2aa6c564158d39d6b177a8d0aee33a44d8d4796&height=200&width=400&legend=true", // Cheerz Logins
    "https://app.datadoghq.eu/graph/embed?token=12fe0af2c49fc851add5cc5a427dcf1dcffb2600846b205e91eead7c68b53c9e&height=200&width=400&legend=true", // KustomBackend - Articles saved
    "https://app.datadoghq.eu/graph/embed?token=b6e5deb5c8033ab15c21095a67363cac4f2515c591f962fe1a827b97f054a490&height=200&width=400&legend=true", // Fotom - Upload
    "https://app.datadoghq.eu/graph/embed?token=879fba5903eeadd02025a953159eba5b17bca0b7ab8217a1978ada9d5be1fd4e&height=200&width=400&legend=true", // Errors
    "https://app.datadoghq.eu/graph/embed?token=848719b86cd22ab34eb998ec194f86fdd3b150530423e9c114754325b7ac11a2&height=200&width=400&legend=true", // ProcessOutInvoice - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=8b8e4327c9b5e62d10e6ddf60fdcd36c706aac6bac1c902416e7debf05d96da9&height=200&width=400&legend=true", // ProcessOutCard - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=05fab3d29da1ad174d870bfac0f82685f41b512a7cebec5d5e0aeeeab4f16e3a&height=200&width=400&legend=true", // ProcessOutTransaction - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=8a250c8a304d5c4359612c5f271f4b374e7b3239a1da9919d21beb8f674d9994&height=200&width=400&legend=true", // ProcessOutInvoice - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=4654424fb7fd92eae3dd82431c4e915cb2aac71586d3a2d6627df63976f0e1e7&height=200&width=400&legend=true", // ProcessOutCard - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=4a440e6da042a299779f327e41151c5a5da07d819bf98321b9a7d0a2e8f13559&height=200&width=400&legend=true", // ProcessOutTransaction - create (Error Rate)
  ],
  datadog_four_hours_urls: [
    "https://app.datadoghq.eu/graph/embed?token=194289ddcdd6aafa10690feeb8edaadc43dfca994053132c8dc6f1acebc0a994&height=200&width=400&legend=true", // Konnektor - Orders Paid - iOS
    "https://app.datadoghq.eu/graph/embed?token=713e12e4bb6e7fd3202b616186dd197b1dd814c28189be1a702b984875f8dc2a&height=200&width=400&legend=true", // Konnektor - Orders Paid - Android
    "https://app.datadoghq.eu/graph/embed?token=d05b6c3848b2202a2c705ba8c833914cbe6d4398bed3fce2b2874761f8e66e97&height=200&width=400&legend=true", // Konnektor - Orders Paid - Web
    "https://app.datadoghq.eu/graph/embed?token=924496bfc485550abfb95d5044b9eeed3fb44145b75267f3b649c55f1e01a63f&height=200&width=400&legend=true", // Konnektor - Orders Paid
    "https://app.datadoghq.eu/graph/embed?token=6544d4fa11cfed700a8c02c358ea4063cb50c3fa224af26d47a4b607d2cf8c2a&height=200&width=400&legend=true", // Cheerz Register
    "https://app.datadoghq.eu/graph/embed?token=ebd9a2f12cb09db509623e01603d9f2b77c80c3531e1f99ca6f388e773d2c83a&height=200&width=400&legend=true", // Cheerz Logins
    "https://app.datadoghq.eu/graph/embed?token=9702aed235fa8997bb7a9af3587e4c89c0d750f867aa76f63b31da490941e6f3&height=200&width=400&legend=true", // KustomBackend - Articles saved
    "https://app.datadoghq.eu/graph/embed?token=1355b7979f808cbb0791955e236646bf322202c8e1123a9b346c34f6e488a4a1&height=200&width=400&legend=true", // Fotom - Upload
    "https://app.datadoghq.eu/graph/embed?token=485daaf37cf1fca6c027ce5ff0ccf2d47764e6fc8ff326db7f5a7b9b5702c9fc&height=200&width=400&legend=true", // Errors
    "https://app.datadoghq.eu/graph/embed?token=c2ccfb7fd5a2783e0da984749567e3d0614d483db7b9dff091c8fa005ea22526&height=200&width=400&legend=true", // ProcessOutInvoice - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=fbdc079284bb73b520040cb100e50636733b9dd21bda7cd66ee91f65c1c9431b&height=200&width=400&legend=true", // ProcessOutCard - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=73e2a4804bdb13cb5c7f1cd7c34de76c90aaf306270fc36aac975ed2659c7962&height=200&width=400&legend=true", // ProcessOutTransaction - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=d960ff76b77e66047844baa3efd648a98dc8e85a2b7c5ad94d168f5642898c15&height=200&width=400&legend=true", // ProcessOutInvoice - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=6d0d680a4f58036ce5278b8ff160b46d201a57e165cd1069e7baeff5d5521279&height=200&width=400&legend=true", // ProcessOutCard - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=32b2718e74ddbec7459f3cfb088fc5ff261355f21716eab831b3c65b6bcc9ced&height=200&width=400&legend=true", // ProcessOutTransaction - create (Error Rate)
  ],
  datadog_one_day_urls: [
    "https://app.datadoghq.eu/graph/embed?token=5f4b92b21cc9ed685576beccf15df519240b0e54149819ddb21394acd28029ac&height=200&width=400&legend=true", // Konnektor - Orders Paid - iOS
    "https://app.datadoghq.eu/graph/embed?token=4b935a9efc0a7d02f475f96380506dea5cb28b8688c87b9ebbe81e38ab551c5a&height=200&width=400&legend=true", // Konnektor - Orders Paid - Android
    "https://app.datadoghq.eu/graph/embed?token=cdc4f89d79efea877c29a94f5cb5ecde5213ee6998de8051959c9be0245c7912&height=200&width=400&legend=true", // Konnektor - Orders Paid - Web
    "https://app.datadoghq.eu/graph/embed?token=47b7ce1909e6d7bd7505ee01b59dffa698afcd9c5dd8116d823494e24d762593&height=200&width=400&legend=true", // Konnektor - Orders Paid
    "https://app.datadoghq.eu/graph/embed?token=731761faa7daa3264d1d78238e1e927ffd102ff95507dff85994c87b5e079f3a&height=200&width=400&legend=true", // Cheerz Register
    "https://app.datadoghq.eu/graph/embed?token=b3c0dfa5718e0ef8b1d9f599a8443982f2b56bf9b7206e5addb2b8a9f65dad7f&height=200&width=400&legend=true", // Cheerz Logins
    "https://app.datadoghq.eu/graph/embed?token=81f32c9f56c8c836682737066ced65a5dd37e6f8fc0b12561e3938520ffad9cb&height=200&width=400&legend=true", // KustomBackend - Articles saved
    "https://app.datadoghq.eu/graph/embed?token=ccdfe2aadcafaed81bd9c3116935723910a32237b3588a378e9cc25a9f7c9216&height=200&width=400&legend=true", // Fotom - Upload
    "https://app.datadoghq.eu/graph/embed?token=7b53dc15a60b33ff3324829d2d24a74ea720c161e6472927281342a95c7005b7&height=200&width=400&legend=true", // Errors
    "https://app.datadoghq.eu/graph/embed?token=1066e70f2e3dce8be21b34810ee5013e4f089928eb3d1dd0ce9f6b1b931830b9&height=200&width=400&legend=true", // ProcessOutInvoice - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=2623c1786ca8a655fd059c5e2e813498c0cc14bdae07ea0b1fd74b5ce57a3c55&height=200&width=400&legend=true", // ProcessOutCard - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=33c50575ea6ebc2923b550c5cfe0efd9b6bf1b81b6b1963d7c745b62eac70cad&height=200&width=400&legend=true", // ProcessOutTransaction - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=5a632630c04588220a796c430148f510072e3c6d7de5e16a557a8b31bc6d8168&height=200&width=400&legend=true", // ProcessOutInvoice - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=67fcc61b574e5f4aebd4cb55d29912c5b65de25531df8509a6ac09e9e44fba63&height=200&width=400&legend=true", // ProcessOutCard - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=f99d85486da14330947a14178d9ef045340825b115ee59e459376dc52197b819&height=200&width=400&legend=true", // ProcessOutTransaction - create (Error Rate)
  ],
  datadog_two_days_urls: [
    "https://app.datadoghq.eu/graph/embed?token=e9600c2d351cb8c93c725a8398add243e06293df5020f39069ebac46151e2e01&height=200&width=400&legend=true", // Konnektor - Orders Paid - iOS
    "https://app.datadoghq.eu/graph/embed?token=a03d4eb93dfbe34c946ebe86c8c1e08aa1043ccd0cdb18bf2ed5f4674f8334c3&height=200&width=400&legend=true", // Konnektor - Orders Paid - Android
    "https://app.datadoghq.eu/graph/embed?token=fe82e0d8295de1ca7eb1eabf5dad2ad8d9034e06df8d932b5638022562d76c12&height=200&width=400&legend=true", // Konnektor - Orders Paid - Web
    "https://app.datadoghq.eu/graph/embed?token=941b545c2617eba4d039cdc3318c80056026769cef5291f7a56485b747fb2078&height=200&width=400&legend=true", // Konnektor - Orders Paid
    "https://app.datadoghq.eu/graph/embed?token=82a961eff2982fc5bfbfd647a25c66b69f4a40709ee4669baed83441b0cff19a&height=200&width=400&legend=true", // Cheerz Register
    "https://app.datadoghq.eu/graph/embed?token=da7a11d1bd01fc790f109a6075fca91a98c02adb6b7059aba341d3ce1b10e951&height=200&width=400&legend=true", // Cheerz Logins
    "https://app.datadoghq.eu/graph/embed?token=c53bab9ab9435b5fcb457c3679e37e98ec204f6ac218cf80cc126ac0dcccdf8f&height=200&width=400&legend=true", // KustomBackend - Articles saved
    "https://app.datadoghq.eu/graph/embed?token=b20d7189de2b9492b19fc9c4e2973d758910a14e3a15ee0342b6308642b384fe&height=200&width=400&legend=true", // Fotom - Upload
    "https://app.datadoghq.eu/graph/embed?token=f33fbd946d443808a380bda945cabc973a2617334e60d2c58be8956f33b8d0e3&height=200&width=400&legend=true", // Errors
    "https://app.datadoghq.eu/graph/embed?token=f46e43b94729e2b59a0012256099f4eef7d4d799c3524eef82f1fc2cb91869e1&height=200&width=400&legend=true", // ProcessOutInvoice - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=9d6379d2c2f057a908226517d7e8d9f9c9064b384a209cd3bcf54108f00a7107&height=200&width=400&legend=true", // ProcessOutCard - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=e891cf5571a7e36de7d713b0e0d7289e280a116828a566cd150633ca8deddc00&height=200&width=400&legend=true", // ProcessOutTransaction - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=a76d212e257908e678cdf0b342236aaeeb669b48946ca4802fe7d06babbed791&height=200&width=400&legend=true", // ProcessOutInvoice - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=e88d2e4efc5e9341de67d788238c31d405162cd199586c1259c329931fa19809&height=200&width=400&legend=true", // ProcessOutCard - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=f521f463770488b7bb055cbdecd152fe718f76883696ade7c04bd8ae3873e85a&height=200&width=400&legend=true", // ProcessOutTransaction - create (Error Rate)
  ],
  datadog_one_week_urls: [
    "https://app.datadoghq.eu/graph/embed?token=16b39a5e290612fd5f5e6f5785983d76de68a350c5e4049ff5cc038f9b587c73&height=200&width=400&legend=true", // Konnektor - Orders Paid - iOS
    "https://app.datadoghq.eu/graph/embed?token=12da1c71671a4ddfd8f9880f5fe2a790a35337c004e605e170b3e52ed62d403d&height=200&width=400&legend=true", // Konnektor - Orders Paid - Android
    "https://app.datadoghq.eu/graph/embed?token=877d2ab14afa5aefe3c59227077a23471df179ebc0c6fc33ad6b385d0d6abb5c&height=200&width=400&legend=true", // Konnektor - Orders Paid - Web
    "https://app.datadoghq.eu/graph/embed?token=df2cf3e98b7efd8a6380fcfd7274297639b2f4ba22bd19dfb2f7dabea2fce6b9&height=200&width=400&legend=true", // Konnektor - Orders Paid
    "https://app.datadoghq.eu/graph/embed?token=8ae44627305400a531fc2ef58a2a81c666374b32323de3a557d3e3f6e1dd9105&height=200&width=400&legend=true", // Cheerz Register
    "https://app.datadoghq.eu/graph/embed?token=fcdcbd5f57f6a5f56524756131913cff3f45f55860ace18c2515592fd4afa678&height=200&width=400&legend=true", // Cheerz Logins
    "https://app.datadoghq.eu/graph/embed?token=ad27da985626ffa1c572f76e0d11439d2851902b29fe5fd7d0dddafe8a6774ab&height=200&width=400&legend=true", // KustomBackend - Articles saved
    "https://app.datadoghq.eu/graph/embed?token=5d1d74b33809c4cd79f56f2a16dbece0d186d98dcb7bde5ae8586b9b98147f2d&height=200&width=400&legend=true", // Fotom - Upload
    "https://app.datadoghq.eu/graph/embed?token=3cdf0cdb43cb437d884bc6262936ebd4c4f165ab8ef7e283bc0ccdf2f23926a1&height=200&width=400&legend=true", // Errors
    "https://app.datadoghq.eu/graph/embed?token=261a10679a898887b8a9101c2829b23be7acfb0d69eafd5ac97fdaa8952755ad&height=200&width=400&legend=true", // ProcessOutInvoice - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=41d36b8645d6dbc595f6a32b1b51544a0a95348cabd14bb034db9b255e3cc6af&height=200&width=400&legend=true", // ProcessOutCard - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=9644743617e45fcee0ff83d3ab360013dc7c96559226ef76f377d4207bc2da2b&height=200&width=400&legend=true", // ProcessOutTransaction - create (Volume)
    "https://app.datadoghq.eu/graph/embed?token=8ea9fac8e9731d9137d0ba3b589d937df8b502e7d3d83f153ddefedee2d0dd62&height=200&width=400&legend=true", // ProcessOutInvoice - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=e029bb4fcd081b19b18da9df911c226339758ec3155711fa1b3f59dedb0ae454&height=200&width=400&legend=true", // ProcessOutCard - create (Error Rate)
    "https://app.datadoghq.eu/graph/embed?token=ffed649a2fc70700bb7085ca4377f7b476ca136860523b95f7227607b8e7c911&height=200&width=400&legend=true", // ProcessOutTransaction - create (Error Rate)
  ],
};

class TechPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: DataTreeFetcher.defaultValue(this.dataTree()),
      range: "one_hour",
    };
  }

  componentDidMount() {
    DataTreeFetcher.dataTree = this.dataTree();
    DataTreeFetcher.subscribe((x) => this.setState({ data: x }));
  }

  dataTree() {
    return {
      unsynced_orders: {
        path: ZkrinAPI.databrickURL("unsynced_orders"),
        refresh: 10,
        initial: { data: { count: null }, updated_at: new Date(0) },
      },

      android_stats: {
        path: ZkrinAPI.databrickURL("android_stats"),
        refresh: 30,
        initial: { data: {}, updated_at: new Date(0) },
      },
      ios_stats: {
        path: ZkrinAPI.databrickURL("ios_stats"),
        refresh: 30,
        initial: { data: {}, updated_at: new Date(0) },
      },

      iphone_day_sales: {
        path: ZkrinAPI.databrickURL("iphone_day_sales"),
        refresh: 30,
        initial: { data: [], updated_at: new Date(0) },
      },
      android_day_sales: {
        path: ZkrinAPI.databrickURL("android_day_sales"),
        refresh: 30,
        initial: { data: [], updated_at: new Date(0) },
      },
      web_day_sales: {
        path: ZkrinAPI.databrickURL("web_day_sales"),
        refresh: 30,
        initial: { data: [], updated_at: new Date(0) },
      },
    };
  }

  render() {
    var handleChange = (e) => {
      this.setState({ range: e.target.value });
    };

    return (
      <div className="zkrin-container">
        <div>
          <div className="flex-grid">
            {this.state.data.iphone_day_sales && (
              <TimeSalesChart
                sales={this.state.data.iphone_day_sales.data}
                height="200"
                width="250"
                title="Iphone"
              />
            )}
            {this.state.data.android_day_sales && (
              <TimeSalesChart
                sales={this.state.data.android_day_sales.data}
                height="200"
                width="250"
                title="Android"
              />
            )}
            {this.state.data.web_day_sales && (
              <TimeSalesChart
                sales={this.state.data.web_day_sales.data}
                height="200"
                width="250"
                title="Web"
              />
            )}
            {this.state.data.ios_stats && (
              <AppDetails
                data={this.state.data.ios_stats.data}
                platformName="iOS"
                style={{ background: "linear-gradient(150deg, #0ad4e8, #0095ff)" }}
              />
            )}
            {this.state.data.android_stats && (
              <AppDetails
                data={this.state.data.android_stats.data}
                platformName="Android"
                style={{ background: "linear-gradient(150deg, #27e069, #1eb15f)" }}
              />
            )}
            {this.state.data.unsynced_orders && (
              <UnsyncedOrders {...this.state.data.unsynced_orders.data} />
            )}
          </div>
        </div>
        <select
          className="range-select"
          name="range"
          id="range-select"
          onChange={(e) => handleChange(e)}
          value={this.state.range}
        >
          <option value="">Select your range</option>
          <option value="one_hour">1h</option>
          <option value="four_hours">4h</option>
          <option value="one_day">1 day</option>
          <option value="four_days">4 days</option>
          <option value="one_week">1 week</option>
        </select>
        <div className="flex-grid">
          {this.state.range === "one_hour" &&
            datadog.datadog_one_hour_urls.map((url) => {
              return <DataDogFrame frameUrl={url} />;
            })}
          {this.state.range === "four_hours" &&
            datadog.datadog_four_hours_urls.map((url) => {
              return <DataDogFrame frameUrl={url} />;
            })}
          {this.state.range === "one_day" &&
            datadog.datadog_one_day_urls.map((url) => {
              return <DataDogFrame frameUrl={url} />;
            })}
          {this.state.range === "four_days" &&
            datadog.datadog_two_days_urls.map((url) => {
              return <DataDogFrame frameUrl={url} />;
            })}
          {this.state.range === "one_week" &&
            datadog.datadog_one_week_urls.map((url) => {
              return <DataDogFrame frameUrl={url} />;
            })}
        </div>
      </div>
    );
  }
}

export default TechPage;
