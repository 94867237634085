import React, { Component } from "react";
import ZkrinAPI from "../../../models/zkrinAPI";
import FlashMessage from "../../FlashMessage";

import "./style.scss";

class AndroidUiTest extends Component {
  pageUrl = `${ZkrinAPI.pages("android_ui_tests")}/${this.props.match.params.build_num}`;

  constructor(props) {
    super(props);
    this.state = {};
    this.removeFlash = this.removeFlash.bind(this);
  }

  removeFlash() {
    this.setState({ message: undefined });
  }

  componentDidMount() {
    fetch(this.pageUrl, { method: "GET", credentials: "include" })
      .then((response) => {
        return Promise.all([response.json(), response.ok]);
      })
      .then(([res, ok]) => {
        if (ok) {
          this.setState({ files: res.files });
        } else {
          throw new Error(res.error);
        }
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  downloadArtifact(url) {
    fetch(`${this.pageUrl}/download?path=${url}`, { method: "GET", credentials: "include" })
      .then((response) => {
        return Promise.all([response.blob(), response.ok]);
      })
      .then(([res, ok]) => {
        if (ok) {
          const href = window.URL.createObjectURL(res);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", url);
          document.body.appendChild(link);
          link.click();
        } else {
          throw new Error("Download failed");
        }
      })
      .catch((err) => {
        this.setState({ message: { type: "error", message: err.message } });
      });
  }

  render() {
    let fileLinks;
    if (this.state.files) {
      fileLinks = (
        <ul>
          {this.state.files.map((file) => (
            <li>
              <button onClick={(e) => this.downloadArtifact(file)}>{file}</button>
            </li>
          ))}
        </ul>
      );
    } else if (this.state.error) {
      fileLinks = `${this.state.error}`;
    }

    return (
      <div className="android-ui-links">
        {this.state.message && (
          <FlashMessage removeFlash={this.removeFlash} {...this.state.message} />
        )}
        {fileLinks}
      </div>
    );
  }
}

export default AndroidUiTest;
