import d3 from "d3";

let DaySalesChart = {};

DaySalesChart.create = function (el, props) {
  DaySalesChart.drawLineChart(el, props);
};

DaySalesChart.update = function (el, props) {
  DaySalesChart.drawLineChart(el, props);
};

DaySalesChart.drawLineChart = function (el, props) {
  d3.select(el).select("svg").remove();

  var margin = { top: 20, right: 20, bottom: 20, left: 20 },
    width = props.width - margin.left - margin.right,
    height = props.height - margin.top - margin.bottom;

  var svg = d3
    .select(el)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  var x = d3.time.scale().range([0, width]).nice();

  var y = d3.scale.linear().range([height, 0]);

  var xAxis = d3.svg.axis().scale(x).tickFormat(d3.time.format("%H:%M")).ticks(5).orient("bottom");

  var sales_dates = props.sales.map(function (s) {
    return s.paid_at;
  });

  var data = sales_dates.sort().reduce(function (a, h, i, _) {
    a[i] = [h, 1 + ((a[i - 1] || [])[1] || 0)];
    return a;
  }, []);

  var line = d3.svg
    .line()
    .x(function (d) {
      return x(Date.parse(d[0]));
    })
    .y(function (d) {
      return y(d[1]);
    });

  x.domain(
    d3.extent(data, function (d) {
      return Date.parse(d[0]);
    })
  );
  y.domain(
    d3.extent(data, function (d) {
      return d[1];
    })
  );

  svg
    .append("g")
    .attr("class", "x axis x-axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis);

  svg.append("path").datum(data).attr("class", "line").attr("d", line);

  var total = 0;
  if (data.length > 0) {
    total = data[data.length - 1][1];
  }

  svg
    .append("text")
    .attr("x", width / 2)
    .attr("class", "orders-count-label")
    .style("text-anchor", "middle")
    .style("fill", "white")
    .style("font-weight", "bold")
    .text(total + " " + props.title);
};
export default DaySalesChart;
