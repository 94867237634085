import React, { Component } from "react";
import { niceDeployerName } from "../../../helpers/deployer";
import moment from "moment";

class StagingDetails extends Component {
  tileStyle() {
    if (this.props.deployer && this.props.deployer.toLowerCase() === "free") {
      return "success-tile";
    } else {
      return "base-tile-background";
    }
  }

  getDateFormat(date) {
    return moment(date && date, "DD/MM/YYYY-hh:mm:ss").toDate();
  }

  getPastDateFormat(date, days) {
    return moment(date && date, "DD/MM/YYYY-hh:mm:ss")
      .add(days, "d")
      .toDate();
  }

  isUpdated() {
    const stagingDeployedDate = this.getDateFormat(this.props.deployed_at);
    const lastDateStagingDeployedDate = this.getPastDateFormat(this.props.deployed_at, 1);
    const lastWeekStagingDeployedDate = this.getPastDateFormat(this.props.deployed_at, 7);
    const prodDeployedDate = this.getDateFormat(this.props.prod.deployed_at);

    if (stagingDeployedDate > prodDeployedDate) {
      return (
        <span role="img" aria-label="updated production" title="updated production">
          ✅
        </span>
      );
    } else if (lastDateStagingDeployedDate >= prodDeployedDate) {
      return (
        <span
          role="img"
          aria-label="outdated production"
          title="outdated production (less than 1 day)"
        >
          ❗️
        </span>
      );
    } else if (lastWeekStagingDeployedDate <= prodDeployedDate) {
      return (
        <span role="img" aria-label="outdated production" title="outdated production (more 7 days)">
          💀
        </span>
      );
    } else {
      return (
        <span role="img" aria-label="outdated production" title="outdated production (less 7 days)">
          ❌
        </span>
      );
    }
  }

  backInfo() {
    if (this.props.name.includes("Cheerz")) {
      return (
        <span className="legend-infos-small light">
          <strong>back</strong>:{" "}
          <a
            style={{ color: "#fff" }}
            href={this.props.repo_url + this.props.branch}
            className="tile-link"
          >
            {this.props.branch ? this.props.branch : ""}
          </a>
        </span>
      );
    } else {
      return (
        <span className="legend-infos-small">
          <a
            style={{ color: "#fff" }}
            href={this.props.repo_url + this.props.branch}
            className="tile-link"
          >
            {this.props.branch ? this.props.branch : ""}
          </a>
        </span>
      );
    }
  }

  render() {
    return (
      <div className={"card thin stat-cell " + this.tileStyle()}>
        <span className="legend-infos top">
          {this.props.name} {this.isUpdated()}
        </span>
        <span className="legend-infos-small light">
          {niceDeployerName(this.props.deployer)} le {this.props.deployed_at}
        </span>
        {this.props["cheerz-front"] ? (
          <span className="legend-infos-small light">
            <strong>front</strong>:
            <a
              style={{ color: "#fff" }}
              href={
                "https://github.com/cheerz/cheerz-front/pulls?q=head:" + this.props["cheerz-front"]
              }
              className="tile-link"
            >
              {this.props["cheerz-front"]}
            </a>
          </span>
        ) : (
          <br></br>
        )}
        {this.props["front-legacy"] ? (
          <span className="legend-infos-small light">
            <strong>legacy</strong>:
            <a
              style={{ color: "#fff" }}
              href={this.props.repo_url + this.props["front-legacy"]}
              className="tile-link"
            >
              {this.props["front-legacy"]}
            </a>
          </span>
        ) : (
          <br></br>
        )}
        {this.backInfo()}
      </div>
    );
  }
}

export default StagingDetails;
