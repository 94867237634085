import React, { Component } from "react";
import DataTreeFetcher from "../../../models/dataTreeFetcher";
import DumpDetails from "../../bricks/DumpDetails";
import FlashMessage from "../../FlashMessage";

class DumpStatus extends Component {
  constructor(props) {
    super(props);
    this.state = DataTreeFetcher.defaultValue(this.dataTree());
    this.removeFlash = this.removeFlash.bind(this);
  }

  removeFlash() {
    this.setState({ message: undefined });
  }

  componentDidMount() {
    DataTreeFetcher.dataTree = this.dataTree();
    DataTreeFetcher.subscribe((x) => this.setState(x));
  }

  updateDumpStatus(status) {
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ value: status }),
    };

    Promise.all(
      Object.entries(this.dataTree()).map(([key, nodeDetails]) =>
        fetch(nodeDetails.path, requestOptions)
          .then((result) => ({ key, success: result.status === 200 }))
          .catch(() => ({ key, success: false }))
      )
    ).then((result) => {
      const failedResults = result.filter((httpResult) => !httpResult.success);
      const message =
        failedResults.length === 0
          ? { type: "success", message: "Update successful" }
          : {
              type: "error",
              message: `Update failed for: ${failedResults
                .map((failed) => failed.key.replace("dump_", ""))
                .join(" ,")}`,
            };

      this.setState({ message: message });
    });
  }

  dataTree() {
    return {
      dump_cheerz_staging1: {
        path: "https://staging1.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: {},
      },
      dump_cheerz_staging2: {
        path: "https://staging2.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_cheerz_staging3: {
        path: "https://staging3.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_cheerz_staging4: {
        path: "https://staging4.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_cheerz_staging5: {
        path: "https://staging5.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_cheerz_staging6: {
        path: "https://staging6.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_cheerz_staging7: {
        path: "https://staging7.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_cheerz_staging8: {
        path: "https://staging8.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_konnektor_staging1: {
        path: "https://staging1.konnektor.io/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_konnektor_staging2: {
        path: "https://staging2.konnektor.io/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
      dump_kustom_staging: {
        path: "https://kustom-staging.cheerz.com/dump_status.json",
        public: true,
        refresh: 3600,
        initial: { status: "error" },
      },
    };
  }

  render() {
    return (
      <div className="zkrin-container">
        {this.state.message && (
          <FlashMessage removeFlash={this.removeFlash} {...this.state.message} />
        )}
        <div className="flex-grid">
          <button className="dash-btn" onClick={(e) => this.updateDumpStatus("run")}>
            Run all
          </button>
          <button className="dash-btn" onClick={(e) => this.updateDumpStatus("skip")}>
            Skip all
          </button>
        </div>
        <br></br>
        <br></br>
        <div className="flex-grid">
          <DumpDetails name="Cheerz Staging 1" {...this.state.dump_cheerz_staging1} />
          <DumpDetails name="Cheerz Staging 2" {...this.state.dump_cheerz_staging2} />
          <DumpDetails name="Cheerz Staging 3" {...this.state.dump_cheerz_staging3} />
          <DumpDetails name="Cheerz Staging 4" {...this.state.dump_cheerz_staging4} />
          <DumpDetails name="Cheerz Staging 5" {...this.state.dump_cheerz_staging5} />
          <DumpDetails name="Cheerz Staging 6" {...this.state.dump_cheerz_staging6} />
          <DumpDetails name="Cheerz Staging 7" {...this.state.dump_cheerz_staging7} />
          <DumpDetails name="Cheerz Staging 8" {...this.state.dump_cheerz_staging8} />
        </div>
        <div className="flex-grid">
          <DumpDetails name="Konnektor Staging 1" {...this.state.dump_konnektor_staging1} />
          <DumpDetails name="Konnektor Staging 2" {...this.state.dump_konnektor_staging2} />
        </div>
        <div className="flex-grid">
          <DumpDetails name="Kustom Staging" {...this.state.dump_kustom_staging} />
        </div>
      </div>
    );
  }
}

export default DumpStatus;
